.experiences-container{

  text-align: center;
  margin-top: 100px;

}

h1 {
  text-align: center;
    padding: 4rem 0 2rem 0;
}

.vertical-timeline-element-date {
  font-size: 1.2rem; /* Adjust the font size as needed */
  font-weight: bold; /* Optionally, adjust the font weight */
}