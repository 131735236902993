.work-container{
    max-width: 100%;
    background: lightskyblue;
}

.project-container{
    max-width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
    padding: 1.2rem 1rem;
}

.project-heading{
    text-align: center;
    padding: 2rem 0;
}

.project-card{
    background: white;
    padding: 1.2rem 1rem;
}
.project-card img{
    width: 100%;
}

.project-card .buttons {
    display: flex;
  justify-content: space-between;
  width: 100%; /* Ensure buttons take full width of the card */
  margin-top: 20px; 
}

.project-title{
    text-align: center;
    padding: 1rem 0;
}

.pro-details p{
    padding-bottom: 1rem;
    font-size: 1.1rem;
    text-align: justify;
}

@media screen and (max-width:740px){
    .project-container{
        max-width: 90%;
        margin: auto;


        grid-template-columns: 1fr;
    }
}