:root {
  --primary-color: black;
  --primary-dark: black;
  --secondary-color: #ffea00;
  --secondary-dark:#e3d002;
  --overlay: rgba(0,0,0,.7);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  line-height: 1.2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.button {
  padding: 17px 28px;
  font-size: 1.2rem;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  background-color: black;
  border: 1px solid white;
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background: white;
  color: black;
  border: 1px solid var(--primary-dark);
}

