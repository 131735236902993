.about {
    background-color:lightskyblue;
    margin: auto;
    padding: 8rem 1rem 0 1rem;
    width: 100%;
}

.about .container {
    width: 1240px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    
}

.line {
    display: block;
    width: 100%; /* Adjust the width of the line */
    height: 2px; /* Adjust the height of the line */
    background-color: black;
    margin: 0 20px; /* Adjust the spacing around the line */
  }

.about .image-left{
    width: 90%;
    margin-left: 15px;
    height: auto;
    float: left;
}

.about h2 {
    font-size: 3rem;
}

.about .col-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    
}

.about .col-2 p:first-of-type {
    margin: 2rem 0;
}

.about .col-2 p:nth-child(4) {
    font-size: 1.8rem;
    font-style: italic;
    font-weight: 600;
}

.about .col-2 p:nth-child(6) {
    font-size: 1.8rem;
    font-style: italic;
    font-weight: 600;
    text-align: left;
    margin-top: 30px;


}

.about button {
    margin-top: 1rem;
}

.about .socialLinks{
        display: flex;
        align-items: center; /* Changed to flex-end */
        list-style: none;
        font-size: 50px;
        z-index: 5;
        margin: 10px;

    
}

.about .socialLinks a{
    margin-right: 10px;
    color: black;
}



.about .socialLinks :hover{
    color: white;
}

@media screen and (max-width:940px) {
    .about .container {
        grid-template-columns: 1fr;
    }

    .about .container {
        width: 100%;
        size: 10px;
    }

    .about .container img {
        padding: 0rem;
        width: 200px;
        justify-content: center;
        align-items: center;
        display: none;
    }

    .about .container .col-2 {
        padding: 0;
    }

    .about button {
        margin-bottom: 1rem;
    }
}